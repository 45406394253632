<template>
  <div>
    <el-col style="margin-top: 10px;margin-left: 10px">
      <el-table
          :row-style="{height:36+'px'}"
          :cell-style="{padding:5+'px'}"
          :data="table.logList"
          border
          height="750"
          :stripe="true"
          :default-sort="{prop: 'createDate', order: 'ascending'}"
          style="width: 100%">
        <el-table-column prop="createDate" width="250" label="错误发生时间" align="center"/>
        <el-table-column prop="modelName" width="250" label="模块名称" align="center"/>
        <el-table-column prop="methodName" width="250" label="操作记录" align="center"/>
        <el-table-column prop="exceptionInfo" width="250" label="错误信息" align="center" :show-overflow-tooltip="true"/>
        <el-table-column prop="params" label="参数" align="center" :show-overflow-tooltip="true"/>
      </el-table>
    </el-col>
    <el-col style="margin-top: 5px">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size="this.table.limit"
          layout="prev, pager, next,total, jumper, sizes"
          :total="table.total">
      </el-pagination>
    </el-col>
  </div>
</template>

<script>

export default {
  name: "receive-exception-log",
  data() {
    return {
      tenantCrop: localStorage.getItem("tenantCrop"),
      table: {
        logList: [],
        page: 1,
        limit: 100,
        total: 0,
      },

    }
  },
  created() {
    this.queryLogList();
  },
  methods: {
    search() {
      this.table.page = 1;
      this.queryLogList();
    },
    //查询客户列表
    queryLogList: function () {
      this.$axios({
        method: "GET",
        url: "/log/receiveExceptionLog",
        params: {
          page: this.table.page,
          limit: this.table.limit,
        }
      }).then(response => {
        this.table.total = response.data.data.total;
        this.table.logList = response.data.data.list;
      })
    },
    //更改每页长度
    handleSizeChange: function (val) {
      this.table.limit = val;
      this.queryLogList();
    },
    handleCurrentChange(val) {
      this.table.page = val
      this.queryLogList()
    },
  }
}
</script>

<style scoped>

</style>